import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { ReactComponent as HeaderLogo } from '../../assets/img/header-logo.svg';
import "./Header.scss";

function CommonHeader(props) {
    const [toggleValue, setToggleValue] = useState(false);

    const onToggle = () => {
        setToggleValue(!toggleValue);
        props.onStatusChange(!toggleValue);
    }

    useEffect(() => {
        setToggleValue(props.sidebarStatus);
    }, [props.sidebarStatus])

    return (
        <div className="header-wrapper">
            <div className="logo-wrapper">
                <HeaderLogo />
            </div>
            <div className="menu-toggle" onClick={onToggle} ></div>
            <div className="user">
                {i18next.t("ADMIN")}
                {/* <div className="profile-picture"></div> */}
            </div>
        </div>
    );
}

export default CommonHeader;