import firebase from "firebase/app";
import 'firebase/auth';
import 'firebase/firestore';
import "firebase/storage";

import i18next from 'i18next';
import { showToast, capitalizeText, showLoader } from "../Utils/common-helper";
import { useHistory } from "react-router-dom";


let storage;
// const firebaseConfig = {
//   apiKey: "AIzaSyBTuZTQ1JTwshTO5ywvJGwjJXKGVSgN93o",
//   authDomain: "watson-discovery-1d3ec.firebaseapp.com",
//   projectId: "watson-discovery-1d3ec",
//   storageBucket: "watson-discovery-1d3ec.appspot.com",
//   messagingSenderId: "603348635610",
//   appId: "1:603348635610:web:46b48d3d3c086d971cbae3",
//   measurementId: "G-0YX655VY4N"
// };

// ***UAT*****
const firebaseConfig = {
  apiKey: "AIzaSyBJJwWQo0CjySJJYmm24Gywn3Kcs-Yk-0U",
  authDomain: "ventyour-d57ee.firebaseapp.com",
  projectId: "ventyour-d57ee",
  storageBucket: "ventyour-d57ee.appspot.com",
  messagingSenderId: "422246882110",
  appId: "1:422246882110:web:4029bf9a649d874bd3aa6f",
  measurementId: "G-PPZDZG2K0T"
};

/**
 * Function to intialize the firebase with the given config setting
 */

if (firebase.apps.length > 0) {
  storage = firebase.storage();
} else {
  // use environment-specific firebase config
  firebase.initializeApp(firebaseConfig);
  storage = firebase.storage();
}

/**
 * Auth
 */
export const auth = firebase.auth;

/**
 * DB
 */
export const firestore = firebase.firestore();

// export const queryRef = firebase.database();

/**
 * getMediaUrl
 */
export const getMediaUrl = (path) => {
  return new Promise((resolve, reject) => {
    storage
      .ref(`${path}`)
      .getDownloadURL()
      .then((url) => {
        resolve(url);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
/**
 * DeleteUsers
 */

export const deleteUser = (userId) => {
  return new Promise((resolve, reject) => {
    firestore.collection("users").doc(userId).delete()
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  })
}

/**
 * DeleteUsers
 */

export const deleteAdminUser = (id) => {
  return new Promise((resolve, reject) => {
    firestore.collection("adminUsers").doc(id.toString()).delete()
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  })
}