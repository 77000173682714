import "./App.scss";
import { Provider } from "react-redux";
import { store, persistor } from "./Utils/store";
import { PersistGate } from "redux-persist/integration/react";
import "./Utils/i18n"; //don't remove this import
import { ToastContainer, toast } from "react-toastify";

// minified version is also included
import "react-toastify/dist/ReactToastify.min.css";
import Layout from "./Components/Layout/index";
toast.configure();

function App() {
  return (
    <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Layout />
        </PersistGate>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        closeButton={true}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        limit={1}
        rtl={false}
        pauseOnFocusLoss={false}
        // draggable
        // pauseOnHover
      />
    </Provider>
  );
}

export default App;
