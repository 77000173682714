import i18next from "i18next";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { debounce } from "throttle-debounce";
import blockIcon from "../../assets/img/block.svg";
import deleteIcon from "../../assets/img/delete.svg";
import dropDownArrow from "../../assets/img/dropdown-arrow.svg";
import reportedIcon from "../../assets/img/flag.svg";
import noActiveUser from "../../assets/img/no_user_found.svg";
import sort from "../../assets/img/sort.svg";
import unblockIcon from "../../assets/img/unblock.svg";
import { saveParamData, saveUserData } from "../../redux/actions/user-management.action";
import { capitalizeText, goClone, showContactNumber, showLoader, showToast } from "../../Utils/common-helper";
import { deleteUser, firestore } from "../../Utils/firebase-helper";
import ConfirmAlert from "../Confirm-Alert";
import FirebaseImage from "../FirebaseImage";
import "./user-management.scss";

// const scrollableContainer = document.querySelector('.scroller');
let filterList = [
  { id: "ALL", name: "All" },
  { id: "ACTIVE", name: "Active" },
  { id: "BLOCKED", name: "Blocked" },
];
let roleList = [
  { id: "all", name: "All" },
  { id: "entrepreneur", name: "Entrepreneur" },
  { id: "investor", name: "Investor" },
  { id: "business", name: "Business" },
];
function UserManagement(props) {
  const initialStateNull = null;
  const [users, setUsers] = useState([]);
  const [latestDoc, setLatestDoc] = useState(initialStateNull);
  const [latestDocRole, setLatestDocRole] = useState(initialStateNull);
  const [modal2, setModal2] = useState(false);
  const [isAllcheckboxSelected, setIsAllCheckBoxSelected] = useState(false);
  const [isAnyCheckBoxSelected, setIsAnyCheckBoxSelected] = useState(false);
  const [isAnyActiveBlockedSelected, setIsAnyActiveBlockedSelected] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [blockusers, showBlock] = useState(false);
  const [unblockusers, showUnblock] = useState(false);
  const [deleteusers, showDelete] = useState(false);
  const [deleteMultipleUsers, showMultiDelete] = useState(false);
  const [userToBeDeleted, setUserToBeDeleted] = useState('');
  const [deleteToggle, setDeleteToggle] = useState(false);
  const [selectedUsersToBeDeleted, setSelectedUsersToBeDeleted] = useState([]);
  const [userInfo, setUserInfo] = useState([]);
  const [checked, setChecked] = useState(false);
  const [filter, setFilter] = useState(false);
  const [sortType, setSortType] = useState('ASC');
  const [selectedRole, setSelectedRole] = useState({ id: "all", name: "All" })

  console.log("abcde", props?.paramData)
  const handleBlock = () => {
    // blockUsers(payloadUsers)
    //   .then((response) => {
    //     showToast(response.message, "success");
    //     const tempParams = { ...params };
    //     tempParams.page = 0;
    //     setParams(tempParams);
    //   })
    //   .catch((error) => {
    //     if (error.data && error.data.message) {
    //       showToast(error.data.message, "error");
    //     }
    //   });
  };

  const handleunblock = () => {
    // unBlockUsers(payloadUsers)
    //   .then((response) => {
    //     showToast(response.message, "success");
    //     const tempParams = { ...params };
    //     tempParams.page = 0;
    //     setParams(tempParams);
    //   })
    //   .catch((error) => {
    //     if (error.data && error.data.message) {
    //       showToast(error.data.message, "error");
    //     }
    //   });
  };

  const handleDelete = () => {
    deleteUser(userToBeDeleted).then((response) => {
      setUsers(users.filter(user => user.userId !== userToBeDeleted))
      showToast(i18next.t('USER_DEL_SUCCESS'));
      setUserToBeDeleted('');
    }).catch((error) => {
      showToast(i18next.t('ERROR_USER_DEL'));
    });
  }
  const handleMultiDelete = () => {
    selectedUsersToBeDeleted?.forEach((item, index, array) => {
      deleteUser(item).then((response) => {
        if (index === array.length - 1) {
          showToast(i18next.t('USERS_DEL_SUCCESS'));
          setIsAnyCheckBoxSelected(false);
          setDeleteToggle(!deleteToggle);
        }

      }).catch((error) => {
        showToast(i18next.t('ERROR_USER_DEL'));
      });
    });
    setSelectedUsersToBeDeleted([]);
  };

  const history = useHistory();
  let [totalPages, setTotalPages] = useState(0);
  let [preSearchText, setPreSearchText] = useState('');

  const getFilteredUsers = async (role) => {
    console.log("role?.id", role?.id)
    const response = firestore.collection('users').where("roleKey", "==", role?.id).orderBy('fullName').limit(12);
    // const response = firestore.collection('users').where("roleKey", "==", role?.id).orderBy('fullName').startAfter(latestDocRole || 0).limit(12);
    const data = await response.get();
    console.log("role?.id data", data)

    let userList = [];
    data.docs.forEach(item => {
      userList.push(item.data());
    })
    setUsers(userList)
    setIsLoading(false);
    //for scrollend
    setLatestDocRole(data.docs[data.docs.length - 1]);
  };
  const handleFilterClick = (role, type) => {
    setSelectedRole(role)
    saveParamData(role)
    if (role?.id === 'all') {
      setIsLoading(true);
      setLatestDoc(initialStateNull)
      getUsers(true)
    }
    else {
      setIsLoading(true);
      getFilteredUsers(role)

    }
  };


  const handleSortClick = (event, sortKey) => {
    if (users.length === 0) {
      return;
    }
    if (sortKey == "fullName") {
      const data = goClone(users);
      if (sortType == 'ASC') {
        data.sort((a, b) => b[sortKey].localeCompare(a[sortKey]))
        setUsers(data)
        setSortType('DESC')
      }
      else {
        data.sort((a, b) => a[sortKey].localeCompare(b[sortKey]))
        setUsers(data)
        setSortType('ASC')
      }
    } else if (sortKey == "role") {
      const data = goClone(users);
      if (sortType == 'ASC') {
        data.sort((a, b) => b[sortKey].localeCompare(a[sortKey]))
        setUsers(data)
        setSortType('DESC')
      }
      else {
        data.sort((a, b) => a[sortKey].localeCompare(b[sortKey]))
        setUsers(data)
        setSortType('ASC')
      }
    }
  };

  const stopEventbubbling = (event) => {
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
  };


  const checkBoxClicked = (e, user, index) => {
    let tempUsers = [...users];
    tempUsers[index].isSelected = e.target.checked ? true : false;
    setUsers(tempUsers);
    isAllChecked(tempUsers);
  };

  const checkAll = (e) => {
    let tempUsers = [...users];
    tempUsers.forEach((x) => (x.isSelected = e.target.checked ? true : false));
    setUsers(tempUsers);
    isAllChecked(tempUsers);
  };

  const handlecheckBoxChange = () => {
    //  empty event as it was controlled input errors on console
  };
  const handleChange = (e, item, index) => {
    stopEventbubbling(e)
    let tempUserInfo = [...users];
    //here we look if your checked item is allready in your array. if so, remove with filter.
    if (tempUserInfo.some((user) => user.id === item.id)) {
      tempUserInfo = tempUserInfo.filter((User) => User.id !== item.id);
      setChecked(true)
    } else {
      tempUserInfo.push(item);
      setChecked(true)
    }
    setUserInfo(tempUserInfo);
    // console.log(userInfo)
  };

  const isAllChecked = (tempUsers) => {
    if (tempUsers.length > 0) {
      let isAllchecked = tempUsers.every((_) => _.isSelected);
      let isSomechecked = tempUsers.some((_) => _.isSelected);
      setIsAllCheckBoxSelected(isAllchecked);
      setIsAnyCheckBoxSelected(isSomechecked);
      if (isSomechecked) {
        let areActiveSelect = false;
        let areBlockedSelect = false;
        tempUsers.forEach((element) => {
          if (element.isSelected && element.isBlocked) {
            areBlockedSelect = true;
          } else if (element.isSelected && !element.isBlocked) {
            areActiveSelect = true;
          }
        });
        if (areBlockedSelect && !areActiveSelect) {
          setIsAnyActiveBlockedSelected("Block");
        } else if (!areBlockedSelect && areActiveSelect) {
          setIsAnyActiveBlockedSelected("Active");
        } else {
          setIsAnyActiveBlockedSelected("");
        }
      }
    } else {
      setIsAllCheckBoxSelected(false);
      setIsAnyCheckBoxSelected(false);
      setIsAnyActiveBlockedSelected("");
    }
  };



  // let latestDoc = null;
  const getUsers = async (filter) => {
    let response = null;
    if (filter) {
      response = firestore.collection('users').orderBy('fullName').startAfter(0).limit(15);
    } else {
      response = firestore.collection('users').orderBy('fullName').startAfter(latestDoc || 0).limit(15);
    }
    const data = await response.get();
    let userList = [];
    data.docs.forEach(item => {
      userList.push(item.data());
    })
    if (filter) {
      console.log("0 end", userList)
      setUsers(userList)
      setFilter(false)
    } else {
      console.log("0 not", userList)
      setUsers(users.concat(userList))
    }
    //for scrollend
    if (data.docs.length > 0) {
      setLatestDoc(data.docs[data.docs.length - 1]);
    }
    setIsLoading(false);

  };
  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      if (selectedRole?.id != 'all') {
        // getFilteredUsers();
      } else {
        getUsers();
      }
    }
  }
  useEffect(() => {
    getUsers();
  }, []);

  const debounceFunc = debounce(1000, (num) => {
    // console.log("num:", num);
  });

  const handleBlockUnblock = (e, user) => {
    stopEventbubbling(e);
    setModal2(!modal2)
    // let users = [];
    // users.push(user.userId);
    // payloadUsers = users;
    // if (user.isBlocked) {
    //   showUnblock(true);
    // } else {
    //   showBlock(true);
    // }
  };

  const handleBlockUnblockSelected = (e) => {
    stopEventbubbling(e);
    // let userIdList = users
    //   .filter((user) => user.isSelected)
    //   .map((rec) => {
    //     return rec.userId;
    //   });
    // payloadUsers = userIdList;
    // if (isAnyActiveBlockedSelected === "Block") {
    //   showUnblock(true);
    // } else {
    //   showBlock(true);
    // }
  };

  const handleDeleteClick = (e, user) => {
    stopEventbubbling(e);
    setUserToBeDeleted(user.userId);
    showDelete(true);
  };

  const handleDeleteSelected = (e) => {
    stopEventbubbling(e);
    showMultiDelete(true);
    let userIdList = users
      .filter((user) => user.isSelected)
      .map((rec) => {
        return rec.userId;
      });
    setSelectedUsersToBeDeleted(userIdList);
    // console.log("object", selectedUsersToBeDeleted)
  };

  const goToUserDetails = (user) => {
    props.saveUserData(user)
    history.push({ pathname: "/user-details", state: { data: user } });
    localStorage.setItem('userID', user.userId)
  };

  const getuserStatus = (user) => {
    let finalStatus = '';
    if (user.isBlocked === true) {
      finalStatus = 'Blocked'
    } else if (user.isBlocked === false && user.reportedByDetails && user.reportedByDetails.length) {
      finalStatus = 'Reported'
    } else if (user.isBlocked === false && (user.reportedByDetails === null || user?.reportedByDetails?.length === 0) && user.approvedType) {
      finalStatus = user.approvedType
    }
    return capitalizeText(finalStatus);
  }


  console.log("abc", selectedRole?.name)
  return (
    <div className="page-container">
      <div className="sub-header-wrapper">
        <div className="title-wrapper">
          <span>{i18next.t("USERS")}</span>
        </div>

        <div className="action-wrapper">
          {isAnyCheckBoxSelected ? (
            <div className="header-buttons">
              {isAnyActiveBlockedSelected ? (
                <img
                  style={{ marginRight: 20 + "px" }}
                  onClick={(e) => handleBlockUnblock(e)}
                  alt={
                    isAnyActiveBlockedSelected === "Block"
                      ? "Unblock Selected"
                      : "Block Selected"
                  }
                  title={
                    isAnyActiveBlockedSelected === "Block"
                      ? "Unblock Selected"
                      : "Block Selected"
                  }
                  src={
                    isAnyActiveBlockedSelected === "Block"
                      ? unblockIcon
                      : blockIcon
                  }
                />
              ) : (
                ""
              )}
              <img
                onClick={(e) => handleDeleteSelected(e)}
                title="Delete Selected"
                alt="Delete Selected"
                src={deleteIcon}
              />

            </div>
          ) : (
            ""
          )}
          <div className="dropdown ">
            <span className="dropdown-label">{i18next.t("ROLE_LABEL")}</span>
            <button
              className="btn  dropdown-toggle"
              type="button"
              id="dropdownMenu2"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <div
                className="selected-dd-text"
                style={{ width: '120px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
                title={selectedRole?.name}>{selectedRole?.name}</div>
              <img
                className="dropDownArrow"
                alt="dropdown"
                src={dropDownArrow}
              />
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
              {roleList.map((filter) => (
                <li
                  key={filter.id}
                  onClick={() => handleFilterClick(filter, "role")}
                >
                  <button className="dropdown-item" type="button">
                    {filter.name}
                  </button>
                </li>
              ))}
            </ul>
          </div>

          <div className="dropdown ">
            <span className="dropdown-label">{i18next.t("VIEW")}</span>
            <button
              className="btn  dropdown-toggle"
              type="button"
              id="dropdownMenu2"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              // disabled
              onClick={(e) => handleBlockUnblock(e)}
              style={{ cursor: 'pointer' }}
            >
              All
              <img
                className="dropDownArrow"
                alt="dropdown"
                src={dropDownArrow}
              />
            </button>
            {/* <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
              {filterList.map((filter) => (
                <li
                  key={filter.id}
                  onClick={() => handleFilterClick(filter, "filter")}
                >
                  <button className="dropdown-item" type="button">
                    {filter.name}
                  </button>
                </li>
              ))}
            </ul> */}
          </div>
          {/* <span className="search-container search-form">
            <span className="ipad-fixes">{' '}</span>
            <img className="search-icon search-icon-user" alt="search" src={searchIcon} />
            <input
              type="text"
              name="search"
              id="search"
              value={preSearchText}
              onChange={(event) => handleSearchChange(event.target.value)}
              onKeyPress={(e) => submitSearch(e)}
              placeholder="Search"
              className="form-control search-background"
            />
            {preSearchText && preSearchText.length > 0 ? (
              <img
                className="clear-search clear-search-user"
                onClick={() => handleClearSearch()}
                alt="cross"
                src={clearSearch}
              />
            ) : (
              ""
            )}
          </span> */}
        </div>
      </div>
      <div className="table-conatiner">
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th className="name-col" width="20%" scope="col">
                <div className="checkbox-container-rem header-checkbox">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      name="checkbox"
                      id="checkbox"
                      // defaultChecked={isAllcheckboxSelected}
                      checked={isAllcheckboxSelected}
                      // value={isAllcheckboxSelected}
                      onChange={handlecheckBoxChange}
                      onClick={(event) => {
                        event.stopPropagation();
                        checkAll(event);
                      }}
                    />
                    <span
                    // onClick={(event) => {
                    //   event.stopPropagation();
                    //   checkAll(event);
                    // }}
                    ></span>
                  </label>
                  <div className="sort-container" onClick={(e) => handleSortClick(e, "fullName")}>
                    <span>{i18next.t("NAME")}</span>
                    <img
                      className="sort-icon"
                      alt="dropdown"
                      src={sort}
                    />
                  </div>
                </div>
              </th>
              <th width="25%" scope="col">
                {i18next.t("EMAIL")}
              </th>
              <th width="15%" scope="col">
                {i18next.t("PHONE_NUMBER")}
              </th>
              <th width="10%" scope="col">
                {/* <div className="sort-container" onClick={(e) => handleSortClick(e, "role")}> */}
                {i18next.t("ROLE")}
                {/* <img
                    className="sort-icon"
                    alt="dropdown"
                    src={sort}
                  /> */}
                {/* </div> */}
              </th>
              {/* <th className="width-10" scope="col">
                {i18next.t("STATUS")}
              </th> */}
              <th
                // className="width-10" 
                width="10%" scope="col">

              </th>
            </tr>
          </thead>
          <tbody id="tbody" className="scroller">
            <InfiniteScroll
              dataLength={users.length} //This is important field to render the next data
              // next={getUsers}
              onScroll={handleScroll}
              hasMore={hasMore}
              // loader={<h4>Loading...</h4>}
              scrollableTarget="tbody"
            >
              {users.map((user, index) => (
                <tr className="row-hover" key={user.userId + 'id00' + index}>
                  <td className="name-col" width="20%">
                    <div style={{ display: 'flex' }}>
                      <div className="checkbox-container-rem  body-checkbox">
                        <label className="checkbox">
                          <input
                            type="checkbox"
                            name={`checkbox${user.userId}`}
                            id={`checkbox${user.userId}`}
                            // defaultChecked={user.isSelected}
                            checked={user?.isSelected}
                            // value={user?.isSelected}
                            // disabled={user.approvedType !== "APPROVED"}
                            onChange={handlecheckBoxChange}
                            onClick={(event) => {
                              event.stopPropagation();
                              checkBoxClicked(event, user, index);
                            }}
                          />
                          <span
                          // onClick={(event) => {
                          //   event.stopPropagation();
                          //   checkBoxClicked(event, user, index);
                          // }}
                          ></span>
                        </label>
                      </div>

                      <div className="user-detail-container">
                        <div onClick={() => goToUserDetails(user)}>
                          <FirebaseImage
                            className=""
                            src={user?.profileImageUrl}
                          />
                        </div>
                        <div className="details-view-table" onClick={() => goToUserDetails(user)}>{user?.fullName ? user?.fullName : '-'}</div>

                      </div>
                    </div>
                  </td>
                  <td
                    width="25%"
                    onClick={() => goToUserDetails(user)}
                  >
                    <div className="details-view-table">
                      {user.email}
                    </div>
                  </td>

                  <td
                    width="15%"
                    onClick={() => goToUserDetails(user)}
                  >
                    <div>
                      {showContactNumber(user?.contactNo, user?.countryCode)}
                    </div>
                  </td>
                  <td
                    width="10%"
                    onClick={() => goToUserDetails(user)}
                  >
                    <div>
                      {`${user?.role ? user?.role : '-'}`}
                    </div></td>


                  <td
                    width="10%"
                  >
                    <div className="roleBtn">
                      {user.status ? (
                        <span
                          className={`role
                      ${user.status.toLowerCase() === "approved"
                              ? "approved"
                              : ""
                            }
                      ${user.status.toLowerCase() === "pending" ? "pending" : ""
                            }
                      ${user.status.toLowerCase() === "rejected"
                              ? "rejected"
                              : ""
                            }
                      `}
                        >
                          {user.status}
                        </span>
                      ) : (
                        ""
                      )}
                      {user.approvedType === "APPROVED"
                        ? <div className="hover-button-container">
                          <div className="hover-button-section">
                            <img
                              onClick={(e) => handleBlockUnblock(e, user)}
                              className=""
                              title={user.isBlocked ? "Unblock" : "Block"}
                              alt={user.isBlocked ? "Unblock" : "Block"}
                              src={user.isBlocked ? unblockIcon : blockIcon}
                            />
                            {user.isReported ? (
                              <img
                                onClick="viewReportedComment(user,i);$event.stopPropagation()"
                                alt="Report"
                                title="Report"
                                src={reportedIcon}
                              />
                            ) : (
                              ""
                            )}

                            <img
                              onClick={(e) => handleDeleteClick(e, user)}
                              title="Delete"
                              alt="Delete"
                              src={deleteIcon}
                            />

                          </div>
                        </div> : null}
                      {/* TEMPORARY */}
                      <div className="hover-button-container">
                        <div className="hover-button-section">
                          <img
                            onClick={(e) => handleBlockUnblock(e)}
                            className=""
                            title={user.isBlocked ? "Unblock" : "Block"}
                            alt={user.isBlocked ? "Unblock" : "Block"}
                            src={user.isBlocked ? unblockIcon : blockIcon}
                          />
                          <img
                            onClick={(e) => handleDeleteClick(e, user)}
                            title="Delete"
                            alt="Delete"
                            src={deleteIcon}
                          />
                        </div>
                      </div>

                    </div>
                  </td>
                </tr>
              ))}
            </InfiniteScroll>
          </tbody>
          {users && users.length === 0 && !isLoading && (
            <div className="text-center no-data">
              <img src={noActiveUser} alt="no active user" />
              <h6 className="no-data-title pt-3">
                {i18next.t("NO_USER_FOUND")}{" "}
              </h6>
            </div>
          )}
        </table>
      </div>

      <ConfirmAlert
        confirmMsg={i18next.t("BLOCK_USER_CONFIRMATION")}
        positiveBTN={i18next.t("BLOCK_LABEL")}
        negativeBTN={i18next.t("CANCEL_LABEL")}
        isOpen={blockusers}
        positiveCallback={() => {
          showBlock(false);
          handleBlock();
        }}
        negativeCallback={() => showBlock(false)}
      />
      <ConfirmAlert
        confirmMsg={i18next.t("UNBLOCK_USER_CONFIRMATION")}
        positiveBTN={i18next.t("UNBLOCK_LABEL")}
        negativeBTN={i18next.t("CANCEL_LABEL")}
        isOpen={unblockusers}
        positiveCallback={() => {
          showUnblock(false);
          handleunblock();
        }}
        negativeCallback={() => showUnblock(false)}
      />
      <ConfirmAlert
        confirmMsg={i18next.t("DELETE_USER_CONFIRMATION")}
        positiveBTN={i18next.t("DELETE_LABEL")}
        negativeBTN={i18next.t("CANCEL_LABEL")}
        isOpen={deleteusers}
        positiveCallback={() => {
          showDelete(false);
          handleDelete();
        }}
        negativeCallback={() => showDelete(false)}
      />
      <ConfirmAlert
        confirmMsg={i18next.t("DELETE_MULTI_USER_CONFIRMATION")}
        positiveBTN={i18next.t("DELETE_LABEL")}
        negativeBTN={i18next.t("CANCEL_LABEL")}
        isOpen={deleteMultipleUsers}
        positiveCallback={() => {
          showMultiDelete(false);
          handleMultiDelete();
        }}
        negativeCallback={() => showMultiDelete(false)}
      />


      {showLoader(isLoading)}


      <ConfirmAlert
        confirmMsg={i18next.t("COMING_SOON_MSG")}
        negativeBTN={i18next.t("OK_LABEL")}
        isOpen={modal2}
        negativeCallback={() => setModal2(false)}
      />
    </div>

  );
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  paramData: state.userManagement.paramData,
  userData: state.userManagement.userData

});

export default connect(mapStateToProps, { saveParamData, saveUserData })(UserManagement);
