import React, { useState } from 'react';
import OtpVerification from "../../Components/OtpVerification";
import AppScreen from "../../Components/AppScreen";
import Logo from "../../Components/Logo";
import Card from "../../Components/Card";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import i18next from 'i18next';
import { showContactNumber, showLoader } from '../../Utils/common-helper'

function OTPVerification(props) {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const queryParams = queryString.parse(location.search);
  const phoneNumber = showContactNumber(queryParams?.phone.slice(1), queryParams?.countryCode);
  return (
    <AppScreen>
      <Card>
        <Logo
          title={i18next.t('OTP_VERIFICATION_T')}
          subtitle={i18next.t('OTP_VERIFICATION_ST')}
          subSubtitle={phoneNumber}
        />
        <OtpVerification isLoading={isLoading} setIsLoading={setIsLoading} />
      </Card>
      {showLoader(isLoading)}
    </AppScreen>
  );
}

export default OTPVerification;