import React, { useState } from 'react';
import './Infocard.scss';

import PDFViewer from '../PDF-Viewer/index';
import i18next from 'i18next';
import FirebaseImage from "../FirebaseImage";


const InfoCard = (props) => {
    const [modal, setModal] = useState(false);
    const [fileDetails, setFileDetails] = useState(null);

    const sortedProfileArray = props?.photoArray?.sort((a, b) => a - b)

    const toggle = (fileName, filePath) => {
        let obj = {
            filename: fileName,
            filepath: filePath
        }
        setFileDetails(obj);
        setModal(!modal);
    }

    return (
        <div className="info-card-wrapper">
            <div className={`info-card ${props?.viewType === 'about' ? 'about-div-margin' : ''}`}>
                <div className={`info-card-title ${props?.viewType === 'about' ? 'bg-white' : ''}`}>
                    <span>{props?.title}</span>
                </div>
                <div className="info-card-body">
                    {props?.viewType === "title-description"
                        ? <>
                            <div className="data-container">
                                <div className="sub-title">
                                    {i18next.t('BUSINESS_NAME')}
                                </div>
                                <div className="sub-title-description">
                                    {props?.businessName}
                                </div>
                            </div>
                            <div className="data-container">
                                <div className="sub-title">
                                    {i18next.t('BUSINESS_LOCATION')}
                                </div>
                                <div className="sub-title-description">
                                    {props?.businessLocation}
                                </div>
                            </div>

                            <div className="data-container">
                                <div className="sub-title">
                                    {i18next.t('BUSINESS_DETAILS')}
                                </div>
                                <div className="sub-title-description">
                                    {props?.businessDetails}
                                </div>
                            </div>

                        </>
                        : props?.viewType === "bio"
                            ? <div className="bio-text">
                                {props?.bioData}
                            </div>

                            : props?.viewType === "photoGrid"
                                ?
                                <div className="profile-pictures">

                                    {props?.photoArray?.map(item => {
                                        if (item?.isProfile) {
                                            return (
                                                <FirebaseImage className="no-margin" src={item?.url} from="user-details" />
                                            )
                                        }
                                    })}

                                    {props?.photoArray?.map(item => {
                                        if (!item?.isProfile) {
                                            return (
                                                <FirebaseImage className="no-margin" src={item?.url} from="user-details" />
                                            )
                                        }
                                    })}

                                </div>
                                : props?.viewType === "profilePhoto"
                                    ?
                                    <div className="profile-pictures">
                                        <FirebaseImage className="no-margin" src={props?.profilePhoto} from="user-details" />
                                    </div>

                                    : null}
                </div>
            </div>

            <PDFViewer
                isOpen={modal}
                onClose={toggle}
                file={fileDetails} />
        </div>
    )
}

export default InfoCard