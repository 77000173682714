import React from 'react';

import UserDetails from '../../Components/User-Details/index';

function UserDetailsPage(props) {
  
  return (
    <UserDetails />
  );
}

export default UserDetailsPage;