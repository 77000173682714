import { USER_MANAGEMENT_PARAMS, USER_DATA } from "../types";

const initialState = {
  paramData: null,
  userData: [],

};

export default function userManagementReducer(state = initialState, action) {
  switch (action.type) {
    case USER_MANAGEMENT_PARAMS:
      return {
        ...state,
        paramData: action.payload,
      };
    case USER_DATA:
      return {
        ...state,
        userData: action.payload,
      };

    default:
      return state;
  }
}