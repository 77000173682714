import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { ReactComponent as SettingsIcon } from "../../assets/img/drawer_setting_Icon.svg";
import { ReactComponent as SettingsIconSelected } from "../../assets/img/drawer_setting_icon_selected.svg";
import { ReactComponent as HeaderLogo } from '../../assets/img/header-logo.svg';
import { ReactComponent as LogoutIcon } from "../../assets/img/logout_icon.svg";
import { ReactComponent as UsersIcon } from "../../assets/img/drawer_user_icon.svg";
import { ReactComponent as UsersIconSelected } from "../../assets/img/drawer_user_icon_selected.svg";
import { saveAuthData } from "../../redux/actions/auth.actions";
import { saveParamData } from "../../redux/actions/user-management.action";
import { showToast } from "../../Utils/common-helper";
import Emitter from "../../Utils/Emitter";
import ConfirmAlert from "../Confirm-Alert/index";
import "./Sidebar.scss";


function Sidebar(props) {
  const location = useLocation();
  const history = useHistory();

  const [sidebarStatus, setSidebarStatus] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);

  const toggle1 = () => setModal1(!modal1);
  const toggle2 = () => setModal2(!modal2);

  const closeConfirmModal = (type) => {
    if (type === 'logout') {
      setModal1(false);
    } else if (type === 'comingSoon') {
      setModal2(false);
    }
  }

  const menuList = [
    {
      name: "USERS_LABEL",
      iconPath: <UsersIcon />,
      iconPathSelected: <UsersIconSelected />,
      urlPath: ["/user-management", "/user-details"],
      goToURL: "",
    },
    {
      name: "SETTINGS_LABEL",
      iconPath: <SettingsIcon />,
      iconPathSelected: <SettingsIconSelected />,
      urlPath: ["/settings"],
      goToURL: "",
    },
    // {
    //   name: "LOGOUT_LABEL",
    //   iconPath: logoutIcon,
    //   urlPath: ["/logout"],
    //   goToURL: "",
    // },
  ];

  const onClose = () => {
    setSidebarStatus(false);
    props.onClose(false);
    // toggle2();
  };

  const logout = (isOauthError) => {
    setSidebarStatus(false);
    props.onClose(false);
    props.saveAuthData(undefined);
    showToast(i18next.t("LOGOUT_SUCCESS_CONFIRMATION"));
    const path = "/login";
    history.replace(path);
    if (isOauthError) {
      showToast(i18next.t("SESSION_TIMOUT"), "info");
    }
  };

  const sidebarClose = () => {
    setSidebarStatus(false);
    props.onClose(false);
    closeConfirmModal('comingSoon')
  };

  const goToScreen = (urlpath) => {
    props.saveParamData([]);
    if (urlpath === '/logout') {
      toggle1();
    } else {
      history.push(urlpath);
    }
    onClose();
  }

  useEffect(() => {
    setSidebarStatus(props.sidebarStatus);
  }, [props.sidebarStatus]);

  useEffect(() => {
  }, [location]);

  // It takes a function
  // useEffect(() => {
  //   Emitter.on("LOGOUT", () => {
  //     logout(true);
  //   });
  //   // unmounting.
  //   return () => {
  //     Emitter.off("LOGOUT");
  //   };
  // }, []);

  return (
    <>
      <div
        className={`overlay-div ${sidebarStatus ? "show-overlay" : ""}`}
        onClick={onClose}
      ></div>
      <div
        className={`sidebar-wrapper ${sidebarStatus ? "open-sidebar" : "close-sidebar"
          }`}
      >
        <div className="sidebar-subwrapper">
          <div className="logo-wrapper">
            <HeaderLogo />
          </div>
          <div className="menu-list">
            <ul>
              {menuList?.map((menu, index) => {
                return (
                  <li
                    className={menu.urlPath.includes(location.pathname) ? "selected-option" : ""}
                    key={`menu${index}`}
                    onClick={
                      index > 0 && index !== 1 ? () => toggle2() : (e) => goToScreen(menu?.urlPath[0])
                      // index > 0 && index !== 2 && index !== 3 && index !== 4 && index !== (menuList.length - 1) ? () => toggle2() : (e) => goToScreen(menu?.urlPath[0])
                    }>
                    <div className="menu-icon">
                      {menu.urlPath.includes(location.pathname) ?
                        menu.iconPathSelected : menu.iconPath}
                    </div>
                    <span>{i18next.t(menu.name)}</span>
                    {/* <span className={index + 1 === menuList.length ? 'logout-text' : ''}>{i18next.t(menu.name)}</span> */}
                  </li>
                );
              })}
            </ul>

            <div className="logout-wrapper">
              <div className="logout" onClick={() => toggle1()}>
                <LogoutIcon />
                <span>{i18next.t("LOGOUT_LABEL")}</span>
              </div>
            </div>

          </div>
        </div>
      </div>
      <ConfirmAlert
        confirmMsg={i18next.t("LOGOUT_CONFIRMATION")}
        positiveBTN={i18next.t("LOGOUT_LABEL")}
        negativeBTN={i18next.t("CANCEL_LABEL")}
        isOpen={modal1}
        positiveCallback={logout}
        negativeCallback={() => closeConfirmModal('logout')}
      />

      <ConfirmAlert
        confirmMsg={i18next.t("COMING_SOON_MSG")}
        negativeBTN={i18next.t("OK_LABEL")}
        isOpen={modal2}
        negativeCallback={sidebarClose}
      />
    </>
  );
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { saveAuthData, saveParamData })(Sidebar);
// export default Sidebar;
