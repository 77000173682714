import { USER_MANAGEMENT_PARAMS, USER_DATA } from "../types";

export const saveParamData = (data) => {
    return (dispatch) => {
      dispatch({ type: USER_MANAGEMENT_PARAMS, payload: data });
    };
};
export const saveUserData = (data) => {
  return (dispatch) => {
    dispatch({ type: USER_DATA, payload: data });
  };
};