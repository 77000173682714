import { Form, Formik } from 'formik';
import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { showToast } from "../Utils/common-helper";
import { countryCodeList } from '../Utils/constants';
import { auth, firestore } from '../Utils/firebase-helper';
import { loginValidation } from "../Utils/ValidationService";
import Dropdown from "./Country-Code-DropDown";
import NumField from "./NumField/index"



function Login(props) {
  const [users, setUsers] = useState([])

  const history = useHistory();
  let { initialValues, validationSchema } = loginValidation();
  const configureCaptcha = (cls) => {
    window.recaptchaVerifier = new auth.RecaptchaVerifier(cls, {
      'size': 'invisible',
      'callback': (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        this.onSignInSubmit();
      },
    });
  }
  const getUsers = async () => {
    const response = firestore.collection('adminUsers');
    const data = await response.get();
    let userList = []
    data.docs.forEach(item => {
      userList.push(item.data()?.contactNo);
    })
    setUsers(userList)
    // console.log("UserList", users)
  };
  useEffect(() => {
    getUsers();
  }, []);

  const handleSubmit = (values) => {
    props.setIsLoading(true);
    // values.preventDefault()
    const phoneNumber = `${values?.country_code}-${values?.mobile_number}`;
    if (users.includes(phoneNumber)) {
      // console.log("verified", phoneNumber)

      configureCaptcha('sign-in-button')
      const appVerifier = window.recaptchaVerifier;
      auth().signInWithPhoneNumber(phoneNumber, appVerifier)
        .then((confirmationResult) => {
          props.setIsLoading(false);
          window.confirmationResult = confirmationResult;
          let path = "/otp-verification";
          history.push(`${path}?phone=%2B${phoneNumber.slice(1)}&countryCode=%2B${values?.country_code.slice(1)}`)
          showToast(i18next.t("OTP_SUCCESS"));
        }).catch((error) => {
          // console.log("Error_login", error)
          showToast(i18next.t("ERROR_INVALID_CREDENTIAL"));
          props.setIsLoading(false);
          setTimeout(() => {
            window.location.reload();
          }, 1200);

        });
    } else {
      showToast(i18next.t("ERROR_INVALID_CREDENTIAL"));
      props.setIsLoading(false);
    }

  }


  const onCheckValid = (data) => {

  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validate={onCheckValid}
        validateOnMount

      >
        {formik => {
          return (
            <Form>
              <div id="sign-in-button"></div>
              <div className='login-container-phone'>
                {/* <Dropdown label={i18next.t("COUNTRY_CODE")} name="country_code" list={countryCodeList} /> */}
                <Dropdown label={i18next.t("CODE")} name="country_code">
                  {/* <option hidden>{i18next.t("COUNTRY_CODE")}</option> */}
                  {countryCodeList?.map(item => {
                    return (
                      <option value={item?.code}>({item?.code}) {item?.name}</option>
                    )
                  })}
                </Dropdown>

                <NumField label={`${i18next.t("PHONE_NUMBER")}*`} name="mobile_number" />

              </div>

              <div className="d-grid gap-2">
                <button className={`btn btn-primary ripple loginBtn`} type="submit" disabled={!formik.isValid} >
                  {i18next.t("LOGIN")}
                </button>
              </div>
            </Form>)
        }}
      </Formik>
    </>

  );
}

export default Login;
