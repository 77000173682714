import i18next from "i18next";
import queryString from "query-string";
import React, { useEffect, useRef, useState } from "react";
import OtpInput from "react-otp-input";
import { connect } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { saveAuthData } from "../../redux/actions/auth.actions";
import { showToast, showLoader } from "../../Utils/common-helper";
import { auth } from '../../Utils/firebase-helper';

function OtpVerification(props) {
  const [otp, setOtp] = useState("");
  const [hasErrored, setHasErrored] = useState(false);
  const [isResendOTP, setIsResendOTP] = useState(false);
  const [remainingTime, setRemainingTime] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const time = useRef(null)
  const queryParams = queryString.parse(location.search);

  const handleChange = (enteredOtp) => {
    setOtp(enteredOtp);
    setHasErrored(false)
  };

  const configureCaptcha = (cls) => {
    window.recaptchaVerifier = new auth.RecaptchaVerifier(cls, {
      'size': 'invisible',
      'callback': (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        this.onSignInSubmit();
      },
    });
  }
  const resendOtp = () => {
    props.setIsLoading(true);
    const number = "+" + queryParams?.phone?.slice(1);
    configureCaptcha('resend-otp-button')
    const appVerifier = window.recaptchaVerifier;
    auth().signInWithPhoneNumber(number, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        props.setIsLoading(false);
        showToast(i18next.t("OTP_SUCCESS"));
      }).catch((error) => {
        props.setIsLoading(false);
        showToast(i18next.t("ERROR_OTP_VERIFICATION"));
      });
  };


  const onSubmitOTP = (payload) => {
    const code = payload.otp
    window.confirmationResult.confirm(code)
      .then((result) => {
        // User signed in successfully.
        props.saveAuthData(payload);
        let path = "/user-management";
        history.replace(path);
      }).catch((error) => {
        // User couldn't sign in (bad verification code?)
        showToast(i18next.t("BAD_OTP_CODE"));


      });
  }

  const handleClick = (e) => {
    e.preventDefault()
    if (otp && otp.length === 6) {
      setHasErrored(false);
      const payload = {
        countryCode: queryParams?.countryCode,
        contactNo: queryParams?.phone,
        oauth2ClientId: "",
        otp: otp,
      };
      // console.log("otp Payload", payload)
      onSubmitOTP(payload);
      clearTimer();
    } else {
      setHasErrored(true);
    }
  };



  const timer = () => {
    let today = new Date();
    today.setSeconds(today.getSeconds() + 32);
    let countDownDate = today.getTime();
    time.current = setInterval(() => {
      // Get today's date and time
      let now = new Date().getTime();
      let distance = countDownDate - now;
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);
      let sec = seconds.toString();
      if (seconds < 10) {
        sec = '0' + seconds.toString();
      }
      setRemainingTime("00:" + sec);
      document.getElementById('demo').innerHTML = "00:" + sec;

      if (distance <= 0) {
        clearInterval(time.current);
        document.getElementById("demo").innerHTML = "";
        setRemainingTime("00:0-1")
        setIsResendOTP(true)
      }
      // if (remainingTime == '00:0-1') { 
      //   setIsResendOTP(true)
      // }
      return isResendOTP;
    }, 1000);
  }


  const clearTimer = () => {
    clearInterval(time.current);
    document.getElementById("demo").innerHTML = "";
    setIsResendOTP(true)
  }

  useEffect(() => {
    timer();
  }, [])

  return (
    <>
      <form onSubmit={handleClick}>
        <div className="otp-input-container" >
          <OtpInput
            value={otp}
            hasErrored={hasErrored}
            onChange={handleChange}
            numInputs={6}
            containerStyle="otp-container"
            focusStyle="on-focus"
            errorStyle="otp-invalid"
            isInputNum
            shouldAutoFocus
            inputStyle="otp-input"
          />
          {hasErrored && (
            <div className="resend-otp-container">
              <span className="error">{i18next.t("OTP_REQUIRED")}</span>
            </div>
          )}
        </div>
        <div className="resend-timer-wrapper">
          <div id="demo"></div>

          <div className="resend-otp-container">
            <p onClick={isResendOTP ? () => resendOtp() : (e) => e.stopPropagation()}
              className={`resend-OTP ${isResendOTP === false ? 'opacity-50' : ''}`}
              aria-disabled={!isResendOTP}>
              {i18next.t("RESEND_OTP")}
            </p>
          </div>
        </div>

        <div className="d-grid gap-2">
          <button
            className={`btn btn-primary ripple loginBtn`}
            type="submit"
            disabled={otp.length != 6}
          >
            {i18next.t("SUBMIT")}
          </button>
        </div>

        <p className="backToSignIn">
          {i18next.t("BACK_TO")}
          <Link to="/login"> {i18next.t("LOGIN")}</Link>
        </p>
        <div id='resend-otp-button'></div>
      </form>
    </>
  );
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
});

export default connect(mapStateToProps, { saveAuthData })(OtpVerification);
