import React, { useState } from 'react';
import AppScreen from "../../Components/AppScreen";
import Card from "../../Components/Card";
import Login from "../../Components/Login";
import { showLoader } from '../../Utils/common-helper';
import Logo from "../../Components/Logo";

function Index(props) {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <AppScreen>
      <Card>
        <div style={{ paddingTop: '40px', paddingBottom: '40px' }}>
          <Logo />
          <Login isLoading={isLoading} setIsLoading={setIsLoading} />
        </div>
      </Card>
      {showLoader(isLoading)}
    </AppScreen>
  );
}

export default Index;