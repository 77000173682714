import { Form, Formik } from 'formik';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import { connect } from "react-redux";
import deleteIcon from "../../assets/img/delete.svg";
import { ReactComponent as UserAvatar } from "../../assets/img/user_avatar_settings.svg";
import Dropdown from '../../Components/Country-Code-DropDown';
import NumField from "../../Components/NumField";
import { showLoader, showToast, showContactNumber } from '../../Utils/common-helper';
import { countryCodeList } from '../../Utils/constants';
import { deleteAdminUser, firestore } from '../../Utils/firebase-helper';
import { loginValidation } from '../../Utils/ValidationService';
import ConfirmAlert from "../Confirm-Alert";
import SubHeader from '../Sub-Header/index';
import './settings.scss';





const Settings = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [adminUsers, setAdminUsers] = useState([]);
    const [userToBeDeleted, setUserToBeDeleted] = useState(false);
    const [deleteusers, showDelete] = useState(false);
    let { initialValues, validationSchema } = loginValidation();


    useEffect(() => {
        getAdminUsers();
    }, [userToBeDeleted]);


    const getAdminUsers = async () => {
        const response = firestore.collection('adminUsers').orderBy('createdAt', 'desc');
        const data = await response.get();
        let userList = []
        data.docs.forEach(item => {
            userList.push(item.data());
        })
        setAdminUsers(userList)
        setIsLoading(false);
    };

    const handleAdd = (values) => {
        let number = `${values?.country_code}-${values?.mobile_number}`;
        let list = []
        adminUsers.forEach(item => {
            list.push(item?.contactNo);
        })
        if (!list.includes(number)) {
            let adminUserRef = firestore.collection("adminUsers");
            adminUserRef.doc(number.toString()).set({
                contactNo: number,
                userId: number,
                countryCode: values?.country_code,
                createdAt: new Date(),
            }).then(() => {
                showToast(i18next.t('USER_ADD_SUCCESS'))
                setUserToBeDeleted(!userToBeDeleted)
            }).catch((error) => {
                showToast(i18next.t('ERROR_USER_ADD_FAILED'))

            });
        } else {
            showToast(i18next.t('USER_ALREADY_EXISTS'))
        }

    }
    const stopEventbubbling = (event) => {
        event.preventDefault();
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
    };

    const handleDelete = () => {
        deleteAdminUser(userToBeDeleted).then((response) => {
            setUserToBeDeleted('');
            showToast(i18next.t('USER_DEL_SUCCESS'));

        }).catch((error) => {
            showToast(i18next.t('ERROR_USER_DEL'));


        });
    }

    const handleDeleteClick = (e, number) => {
        stopEventbubbling(e);
        setUserToBeDeleted(number);
        showDelete(true);
    };


    const onCheckValid = () => { }

    const renderForm = () => {
        return (
            <>
                <div className="role-bar">
                    <span>
                        {i18next.t('ADMINISTRATIVE_ROLE')}
                    </span>
                </div>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values, { ...props }) => {
                        // do your stuff 
                        handleAdd(values)
                        props.setSubmitting(false)
                        props.resetForm()
                        props.validateForm()
                    }}
                    validate={onCheckValid}
                    validateOnMount
                >{formik => {
                    return (
                        <Form>
                            <div className="role-input-container">
                                {/* <TextField label={i18next.t("EMAIL_LABEL")} type="text" name="email" /> */}
                                <Dropdown label={i18next.t("CODE")} name="country_code" style={{ cursor: 'pointer' }}>
                                    {countryCodeList?.map(item => {
                                        return (
                                            <option value={item?.code}>({item?.code}) {item?.name}</option>
                                        )
                                    })}
                                </Dropdown>
                                <NumField label={i18next.t("PHONE_NUMBER")} name="mobile_number" />

                                <div>
                                    <button type="submit" disabled={!formik.isValid} class="btn btn-info">{i18next.t("ADD")}</button>
                                </div>
                            </div>
                        </Form>)
                }}

                </Formik>
            </>
        )
    }
    return (
        <div className="user-details-wrapper">
            {showLoader(isLoading)}
            <SubHeader
                title={i18next.t('SETTINGS_LABEL')}
                isBack={false}
                from={'Settings'}
            />
            <div className="common-wrapper-setting">
                {renderForm()}

                <div className="table-conatiner">
                    <table className="table table-striped table-hover">
                        <tbody id="tbody">
                            <InfiniteScroll
                                dataLength={adminUsers.length} //This is important field to render the next data
                                //next={onScrollTripList}
                                //hasMore={hasMore}
                                // loader={<h4>Loading...</h4>}
                                scrollableTarget="tbody"
                            >
                                <tr style={{ backgroundColor: "#f6f6f6" }}>
                                    <td className="width-10 name-col">
                                        <UserAvatar />
                                        <span style={{ color: "#919191" }}>
                                            {showContactNumber(props?.authData?.contactNo, props?.authData?.countryCode)}
                                        </span>
                                    </td>
                                    <td className="width-15" style={{ color: "#919191" }}>
                                        {/* +{props?.authData?.phoneNumber?.slice(1)} */}
                                    </td>

                                    <td className="width-10"></td>
                                </tr>
                                {adminUsers.map((item, index) => {
                                    if (item?.contactNo != props?.authData?.contactNo) {
                                        return (
                                            <tr className="row-hover" key={`listTrip000${index}`}>
                                                <td className="width-10 name-col">
                                                    <UserAvatar />
                                                    <span>
                                                        {showContactNumber(item?.contactNo, item?.countryCode)}
                                                    </span>
                                                </td>
                                                <td className="width-15">
                                                    {/* {item?.contactNo} */}
                                                </td>

                                                <td className="width-10">
                                                    <div className="roleBtn">
                                                        {/* <div className="hover-button-container"> */}
                                                        <div className="hover-button-section">
                                                            <img
                                                                onClick={(e) => handleDeleteClick(e, item?.contactNo)}
                                                                title="Delete"
                                                                alt="Delete"
                                                                src={deleteIcon}
                                                                style={{ cursor: 'pointer' }}
                                                            />
                                                        </div>
                                                        {/* </div> */}
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    } else {

                                    }
                                })}


                            </InfiniteScroll>
                        </tbody>

                        {adminUsers && adminUsers.length === 0 && !isLoading && (
                            <div className="text-center no-data">
                                <img src="#" alt="no active user" />
                                <h6 className="no-data-title pt-3">
                                    {i18next.t("NO_DATA_FOUND")}
                                </h6>
                            </div>
                        )}
                    </table>
                </div>

            </div>
            <ConfirmAlert
                confirmMsg={i18next.t("DELETE_USER_CONFIRMATION")}
                positiveBTN={i18next.t("DELETE_LABEL")}
                negativeBTN={i18next.t("CANCEL_LABEL")}
                isOpen={deleteusers}
                positiveCallback={() => {
                    showDelete(false);
                    handleDelete();
                }}
                negativeCallback={() => showDelete(false)}
            />


            {showLoader(isLoading)}

        </div >
    )
}

const mapStateToProps = (state) => ({
    authData: state.auth.authData,

});

export default connect(mapStateToProps, {})(Settings);
