import React, { useState, useEffect } from 'react';
import { Modal, ModalBody } from 'reactstrap';

const ConfirmAlert = (props) => {
    const [modal, setModal] = useState(false);

    const toggle = () => {
        setModal(!modal);
    };

    const onPositiveBTNClick = () => {
        props.positiveCallback();
        toggle();
    }

    const onNegativeBTNClick = () => {
        props.negativeCallback();
        //setModal(false)
    }

    const onClose = () => {
        setModal(false)
    }

    useEffect(() => {
        setModal(props.isOpen);
    }, [props.isOpen])

    useEffect(() => {
        if(modal === false)
        onNegativeBTNClick();
    }, [modal])

    return(
        <Modal isOpen={modal} toggle={toggle} className={'confirm-alert'} centered>
            <ModalBody>
                <div className="confirm-text">
                    {props?.confirmMsg}
                </div>
                <div className="btn-div">
                    {props?.positiveBTN ?
                        <button 
                            type="button" 
                            className={`outline-btn ripple-light ${props.customClass ? props.customClass : ''}`}
                            onClick={onPositiveBTNClick}>
                                {props?.positiveBTN}
                        </button>
                    : null }
                    {props?.negativeBTN
                    ?   <button
                            type="button"
                            className="solid-btn ripple"
                            onClick={onClose}>
                                {props?.negativeBTN}
                        </button>
                    :   null}
                </div>
            </ModalBody>
        </Modal>
    )
}

export default ConfirmAlert;