import * as Yup from 'yup';
import i18next from 'i18next';

// const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const phoneRegExp = /^[0-9]{10}$/
export const loginValidation = () => {
  return {
    initialValues: {
      mobile_number: '',
      country_code: '+1',
      isInitialValid: false,
    },
    validationSchema: Yup.object({
      mobile_number: Yup.string().required(i18next.t('PHONE_REQUIRED')).matches(phoneRegExp, (i18next.t('INVALID_PHONE_NUMBER'))),
      // country_code: Yup.string().required(i18next.t('COUNTRY_CODE_REQUIRED'))
    })
  }

};


