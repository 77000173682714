import React from "react";
import { Redirect, Switch } from "react-router-dom";
import NotProtectedRoute from './Components/NotProtectedRoute';
import ProtectedRoute from "./Components/ProtectedRoute";
import Login from "./screens/login";
import OtpVerification from "./screens/otp-verification";
import UserDetailsPage from "./screens/user-details";
import UserManagement from "./screens/user-management";
import Settings from "./screens/settings";


export function routing(authData) {
  return (
    <>
      <Switch>
        <ProtectedRoute
          isAuth={authData}
          path="/user-management"
          component={UserManagement}
        />
        <ProtectedRoute
          isAuth={authData}
          path="/user-details"
          component={UserDetailsPage}
        />
        <ProtectedRoute
          isAuth={authData}
          path="/settings"
          component={Settings}
        />
        <NotProtectedRoute isAuth={authData} path="/otp-verification" component={OtpVerification} />
        <NotProtectedRoute isAuth={authData} path="/login" component={Login} />
        <Redirect path="/" exact to={authData ? "/user-management" : "/login"} />
        <Redirect to={authData ? "/user-management" : "/login"} />
      </Switch>
    </>
  );
}
