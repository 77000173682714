import { ErrorMessage, useField } from "formik";
import React from "react";
import i18next from "i18next";
import "./TextField.scss";

function CountryCodeDropDown({ label, ...props }) {
  const [field, meta] = useField(props);
  return (

    // <div className="input-container-dropdown">
    //   {/* <label htmlFor={props.id || props.name}>{label}</label> */}
    //   <select {...field} {...props} className={`form-select shadow-none ${meta.touched && meta.error && "is-invalid"
    //     }`} aria-label="Default select example" />
    //   <ErrorMessage component="span" name={field.name} className="error" />

    // </div>
    <div className="input-container-dropdown">
      <div className="form-group has-float-label">
        <select {...field} {...props} className={`form-select shadow-none ${meta.touched && meta.error && "is-invalid"
          }`} aria-label="Default select example" />
        <label >{label}</label>
      </div>
      <ErrorMessage component="span" name={field.name} className="error" />
    </div>
  );
}

export default CountryCodeDropDown;
