import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { capitalizeText, showContactNumber, showLoader, showToast } from '../../Utils/common-helper';
import { deleteUser } from '../../Utils/firebase-helper';
import FirebaseImage from "../FirebaseImage";
import InfoCard from '../Info-Card/index';
import SubHeader from '../Sub-Header/index';
import "./user-details.scss";

const photoArray = ['First']

function UserDetails(props) {
    const location = useLocation();
    const history = useHistory();
    const [userDetails, setUserDetails] = useState(null);
    const [userData, setUserData] = useState(null);
    const [userId, setUserId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isBlocked, setIsBlockedisBlocked] = useState(true);
    const [approvedType, setApprovedType] = useState(null);
    const onBlockUser = () => {
        let payload = [];
        payload.push(userId)
        // api.blockUsers(payload)
        //     .then(response => {
        //         if (response && response.data && response.code === 200) {
        //             // getDetails(location.state.data.userId);
        //             getDetails(localStorage.getItem('userID'))
        //         }
        //     })
        //     .catch(error => {
        //         if(error.data && error.data.message){
        //             showToast(error.data.message, "error");
        //         }
        //     })
    }

    const onUnblockUser = () => {
        let payload = [];
        payload.push(userId)
        // api.unBlockUsers(payload)
        //     .then(response => {
        //         if (response && response.data && response.code === 200) {
        //             // getDetails(location.state.data.userId);
        //             getDetails(localStorage.getItem('userID'))
        //         }
        //     })
        //     .catch(error => {
        //         if(error.data && error.data.message){
        //             showToast(error.data.message, "error");
        //         }
        //     })
    }

    const onDeleteUser = () => {
        deleteUser(userDetails?.userId)
            .then((response) => {
                // console.log("Document successfully deleted!");
                showToast("User successfully deleted!");
                history.goBack()

            }).catch((error) => {
                // console.error("Error removing document: ", error);
                showToast(error, "Error removing document:");

            });
    }

    const onApproveUser = () => {
        // api.approveUser(userId)
        //     .then(response => {
        //         if (response && response.data && response.code === 200) {
        //             //  getDetails(location.state.data.userId);
        //             getDetails(localStorage.getItem('userID'))
        //         }
        //     })
        //     .catch(error => {
        //         if(error.data && error.data.message){
        //             showToast(error.data.message, "error");
        //         }
        //     })
    }

    const onRejectUser = () => {
        // api.rejectUser(userId)
        //     .then(response => {
        //         if (response && response.data && response.code === 200) {
        //             //getDetails(location.state.data.userId);
        //             getDetails(localStorage.getItem('userID'))
        //         }
        //     })
        //     .catch(error => {
        //         if(error.data && error.data.message){
        //             showToast(error.data.message, "error");
        //         }
        //     })
    }

    const getDetails = (userId) => {
        setIsLoading(false);
        // api.getUserDetails(userId)
        //     .then(response => {
        //         setIsLoading(false);
        //         if (response && response.data && response.code === 200) {
        //             if (response.data.aircraftOwnerProfileDTO) {
        //                 setUserDetails(response.data.aircraftOwnerProfileDTO);
        //             }else if (response.data.aviationCompanyProfileDTO){
        //                 setUserDetails(response.data.aviationCompanyProfileDTO);
        //             }else if (response.data.flightAttendantProfileDTO){
        //                 setUserDetails(response.data.flightAttendantProfileDTO);
        //             }else if (response.data.pilotProfileDTO){
        //                 setUserDetails(response.data.pilotProfileDTO);
        //             }
        //             setApprovedType(response?.data?.approvedType);
        //             setUserId(response?.data?.userId)
        //         }
        //     })
        //     .catch(error => {
        //         setIsLoading(false);
        //         if(error.data && error.data.message){
        //             showToast(error.data.message, "error");
        //         }
        //     })
    }

    const getFinalURL = (url) => {
        let trimUrl = url.toLowerCase().trim();
        if (trimUrl) {
            if (trimUrl.startsWith("https://")) {
                return trimUrl;
            } else {
                return 'https://' + trimUrl;
            }
        }

    }

    const getuserStatus = () => {
        let user = userDetails
        let finalStatus = '';
        if (user?.isBlocked && user?.isBlocked === true) {
            finalStatus = 'Blocked'
        } else if (user?.isBlocked && user?.isBlocked === false && user.reportedByDetails && user.reportedByDetails.length) {
            finalStatus = 'Reported';
        } else {
            if (approvedType)
                finalStatus = approvedType;
        }
        return capitalizeText(finalStatus);
    }

    useEffect(() => {
        setUserDetails(props?.userData);

        if (location?.state?.data?.userId) {
            getDetails(location?.state?.data?.userId);
        } else {
            getDetails(localStorage.getItem('userID'));
        }
    }, []);
    return (
        <div className="user-details-wrapper">
            {showLoader(isLoading)}
            <SubHeader
                title={i18next.t('USER_DETAILS')}
                from="User Details"
                isBack={true}
                onDeleteUser={onDeleteUser}
                deleteConfrmMsg={i18next.t('DELETE_USER_CONFIRMATION')}
                isApproved={false}
                isBlocked={true}

            />

            <div className="common-wrapper">
                <div className="personal-info-wrapper">
                    <div className="title">{i18next.t('BASIC_INFO_LABEL')}</div>
                    <div className="info-container">
                        <div className="profile-pic-section">
                            <div className="profile-pict">
                                <FirebaseImage className="no-margin" src={userDetails?.profileImageUrl} from="user-details" />
                            </div>
                            {/* <div 
                                className={`user-status ${getuserStatus() === 'Pending' ? 'pending-text' : getuserStatus() === 'Approved' ? 'approve-text' : getuserStatus() === 'Rejected' ? 'rejected-text' : getuserStatus() === 'Blocked' ? 'blocked-text' : getuserStatus() === 'Reported' ? 'reported-text' : ''}`}>
                                {getuserStatus()}
                            </div> */}
                        </div>
                        <div className="profile-info-section">
                            <div className="personal-info">
                                <div className="row-div">
                                    <div className="key-text">{i18next.t('NAME')}:</div>
                                    <div className="value-text">{userDetails?.fullName ? userDetails?.fullName : ''}</div>
                                </div>
                                <div className="row-div">
                                    <div className="key-text">{i18next.t('ROLE')}:</div>
                                    <div className="value-text">{userDetails?.role}</div>
                                </div>
                                <div className="row-div">
                                    <div className="key-text">{i18next.t('PHONE_LABEL')}:</div>
                                    <div className="value-text">{`${userDetails?.contactNo ? showContactNumber(userDetails?.contactNo, userDetails?.countryCode,) : ''}`} </div>
                                </div>
                                <div className="row-div">
                                    <div className="key-text">{i18next.t('EMAIL')}:</div>
                                    <div className="value-text">{userDetails?.email}</div>
                                </div>
                                <div className="row-div">
                                    <div className="key-text">{i18next.t('WEBSITE_ADDRESS')}:</div>
                                    {userDetails?.website ?
                                        <a className="value-link" href={getFinalURL(userDetails?.website)} target="_blank" rel="noopener noreferrer">{userDetails?.website ? userDetails?.website.toLowerCase() : 'NA'}</a>
                                        : <div className="value-text">{i18next.t('NA')}</div>
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {userDetails?.imageUrls ?
                    <InfoCard title={i18next.t('PROFILE_PICTURES')} viewType="photoGrid" photoArray={userDetails?.imageUrls} />
                    : <InfoCard title={i18next.t('PROFILE_PICTURES')} viewType="profilePhoto" profilePhoto={userDetails?.profileImageUrl} />}
                <InfoCard title={i18next.t('BIO')} viewType="bio" bioData={userDetails?.bio ? userDetails?.bio : "-"} />
                <InfoCard title={i18next.t('BUSINESS_INFORMATION')} viewType="title-description" businessName={userDetails?.businessInfo?.name ? userDetails?.businessInfo?.name : "-"} businessLocation={userDetails?.businessInfo?.location ? userDetails?.businessInfo?.location : "-"} businessDetails={userDetails?.businessInfo?.bio ? userDetails?.businessInfo?.bio : "-"} />


            </div>
        </div >
    );
}

const mapStateToProps = (state) => ({
    authData: state.auth.authData,
    paramData: state.userManagement.paramData,
    userData: state.userManagement.userData,
});

export default connect(mapStateToProps, {})(UserDetails);
