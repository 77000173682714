import React from "react";
import { ReactComponent as AppLogo } from "../../assets/img/logo.svg";
import styles from "./Logo.module.scss";


function Logo({ title, subtitle, subSubtitle }) {
  return (
    <>
      <div className={`${styles.logoContainer} ${title ? styles.withTitle : ""}`}>
        <AppLogo />
      </div>
      {title && <p className={`${styles.title} ${!subtitle ? styles.withOutSubtitle : ""}`}>{title}</p>}
      {subtitle && <p className={styles.subTitle}>{subtitle}</p>}
      {subSubtitle && <p className={styles.subSubTitle}>{subSubtitle}</p>}

    </>
  );
}

export default Logo;
