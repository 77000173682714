import React from 'react';

import UserManagement from '../../Components/UserManagement';

function index(props) {
  
  return (
    <UserManagement />
  );
}

export default index;