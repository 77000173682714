import React from 'react';

import Settings from '../../Components/Settings';

function SettingsPage(props) {
  
  return (
    <Settings />
  );
}

export default SettingsPage;