import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from '../assets/locales/en/translation.json';

const resources = {
    en: {
        translation: translationEN
    }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en',
    debug: true,
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
