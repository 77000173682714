import React from "react";
import styles from "./AppScreen.module.scss";


function AppScreen({ children }) {
  return (
    <>
      <div className={styles.container}>
        <main className={styles.main}>
          {children}
        </main>
      </div>
    </>
  );
}

export default AppScreen;
