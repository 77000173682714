import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authReducer from "./auth.reducers";
import userManagementReducer from "./user-management.reducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "userManagement"], //name of value you wanna persist
};
const rootReduces = combineReducers({
  auth: authReducer,
  userManagement: userManagementReducer,
});

export default persistReducer(persistConfig, rootReduces);
