import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import i18next from 'i18next';
import "./SubHeader.scss";
import backArrow from '../../assets/img/go_back_arrow.svg';
import clearSearch from "../../assets/img/black-cross.svg";
import searchIcon from "../../assets/img/search-icon.svg";
import ConfirmAlert from '../Confirm-Alert/index';



function SubHeader(props) {
    const [modal1, setModal1] = useState(false);
    const [modal2, setModal2] = useState(false);
    const [modal3, setModal3] = useState(false);
    const [modal4, setModal4] = useState(false);
    const [modal5, setModal5] = useState(false);
    const [modal6, setModal6] = useState(false);
    const [preSearchText, setPreSearchText] = useState('');

    let history = useHistory();

    // const toggle1 = () => setModal1(!modal1);
    // const toggle2 = () => setModal2(!modal2);
    // const toggle3 = () => setModal3(!modal3);
    // const toggle4 = () => setModal4(!modal4);
    // const toggle5 = () => setModal5(!modal5);

    const toggle1 = (status) => setModal1(status);
    const toggle2 = (status) => setModal2(status);
    const toggle3 = (status) => setModal3(status);
    const toggle4 = (status) => setModal4(status);
    const toggle5 = (status) => setModal5(status);
    const toggle6 = (status) => setModal6(status);



    const onBlock = () => {
        props.onBlockUser();
        toggle1(false);
    }

    const onUnblock = () => {
        props.onUnblockUser();
        toggle2(false);
    }

    const onDelete = () => {
        props.onDeleteUser();
        toggle3(false);
    }

    const onApprove = () => {
        props.onApproveUser();
        toggle4(false);
    }

    const onReject = () => {
        props.onRejectUser();
        toggle5(false);
    }
    const onTest = () => {
        toggle6(false);
    }

    const handleSearchChange = (text) => {
        setPreSearchText(text);
    };

    const submitSearch = (e) => {
        if (e.charCode == 13) {
            const tempParams = { ...props.params };
            tempParams.searchText = preSearchText;
            tempParams.page = 0;
            props.setParams(tempParams);
        }
    }

    const handleClearSearch = () => {
        const tempParams = { ...props.params };
        tempParams.searchText = "";
        tempParams.page = 0;
        props.setParams(tempParams);
        setPreSearchText('');
    }

    return (
        <div className="sub-header-wrapper">
            <div className="title-wrapper">
                {props?.isBack ? <img src={backArrow} alt="back-arrow" onClick={history.goBack} /> : null}
                <span className={`text ${props?.isBack ? 'arrow-text' : ''}`}>{props?.title}</span>
            </div>

            <div className="action-wrapper action-wrapper-common">
                {(props?.from === "User Details" && props.isApproved !== null)
                    ? <>
                        {!props?.isBlocked && props?.isApproved
                            ? <div className="block-btn" title={i18next.t('BLOCK_LABEL')}>
                                <button
                                    type="button"
                                    className="btns ripple-light"
                                    onClick={() => toggle1(true)}>
                                    {i18next.t('BLOCK_LABEL')}
                                </button>
                            </div>
                            : props?.isApproved
                                ? <div className="block-btn" title={i18next.t('BLOCK_LABEL')}>
                                    <button
                                        type="button"
                                        className="btns ripple-light"
                                        onClick={() => toggle2(true)}>
                                        {i18next.t('UNBLOCK_LABEL')}
                                    </button>
                                </div>
                                : null}

                        {!props?.isApproved && props?.from === "User Details"
                            ?
                            <>
                                <div className="block-btn" title={i18next.t('BLOCK_LABEL')}>
                                    <button
                                        type="button"
                                        className="btns ripple-light"
                                        onClick={() => toggle6(true)}>
                                        {i18next.t('BLOCK_LABEL')}
                                    </button>
                                </div>

                                <div className="delete-btn" title={i18next.t('DELETE_LABEL')}>
                                    <button
                                        type="button"
                                        className="btns ripple-light"
                                        onClick={() => toggle5(true)}>
                                        {i18next.t('DELETE_LABEL')}
                                    </button>
                                </div>
                            </>
                            : null}

                    </>
                    : null}

                {props.isSearchBar
                    ? <span className="search-container search-form">
                        <img className="search-icon" alt="search" src={searchIcon} />
                        <input
                            type="text"
                            name="search"
                            id="search"
                            value={preSearchText}
                            onChange={(event) => handleSearchChange(event.target.value)}
                            onKeyPress={(e) => submitSearch(e)}
                            placeholder="Search"
                            className="form-control search-background"
                        />
                        {preSearchText && preSearchText.length > 0 ? (
                            <img
                                className="clear-search"
                                onClick={() => handleClearSearch()}
                                alt="cross"
                                src={clearSearch}
                            />
                        ) : (
                            ""
                        )}
                    </span>
                    : null}

            </div>

            <ConfirmAlert
                confirmMsg={i18next.t('BLOCK_USER_CONFIRMATION')}
                positiveBTN={i18next.t('BLOCK_LABEL')}
                negativeBTN={i18next.t('CANCEL_LABEL')}
                isOpen={modal1}
                customClass={'danger-btn'}
                positiveCallback={onBlock}
                negativeCallback={() => setModal1(false)} />

            <ConfirmAlert
                confirmMsg={i18next.t('UNBLOCK_USER_CONFIRMATION')}
                positiveBTN={i18next.t('UNBLOCK_LABEL')}
                negativeBTN={i18next.t('CANCEL_LABEL')}
                isOpen={modal2}
                positiveCallback={onUnblock}
                negativeCallback={() => setModal2(false)} />

            <ConfirmAlert
                confirmMsg={props?.deleteConfrmMsg && props.deleteConfrmMsg !== '' ? props.deleteConfrmMsg : ''}
                positiveBTN={i18next.t('DELETE_LABEL')}
                negativeBTN={i18next.t('CANCEL_LABEL')}
                isOpen={modal3}
                customClass={'danger-btn'}
                positiveCallback={onDelete}
                negativeCallback={() => setModal3(false)} />

            <ConfirmAlert
                confirmMsg={i18next.t('APPROVE_USER_CONFIRMATION')}
                positiveBTN={i18next.t('APPROVE_LABEL')}
                negativeBTN={i18next.t('CANCEL_LABEL')}
                isOpen={modal4}
                customClass={'success-btn'}
                positiveCallback={onApprove}
                negativeCallback={() => setModal4(false)} />

            <ConfirmAlert
                confirmMsg={i18next.t('DELETE_USER_CONFIRMATION')}
                positiveBTN={i18next.t('DELETE_LABEL')}
                negativeBTN={i18next.t('CANCEL_LABEL')}
                isOpen={modal5}
                customClass={'danger-btn'}
                positiveCallback={onDelete}
                negativeCallback={() => setModal5(false)} />
            <ConfirmAlert
                confirmMsg={i18next.t("COMING_SOON_MSG")}
                negativeBTN={i18next.t("OK_LABEL")}
                isOpen={modal6}
                negativeCallback={() => setModal6(false)}
            />

        </div>
    );
}

export default SubHeader;