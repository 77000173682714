import React, { useState } from "react";
import { useField, ErrorMessage } from "formik";
import "./NumField.scss";
import hideIcon from "../../assets/img/hide.svg";
import showIcon from "../../assets/img/show.svg";

function NumField({ label, type, ...props }) {
  const [field, meta] = useField(props);
  const [assignedType, setAssignedType] = useState(type);
  const changePasswordType = () => {
    const _assignedType = assignedType === "password" ? 'text' : "password";
    setAssignedType(_assignedType);
  };
  return (
    <div className="input-container">
      <div className="form-group has-float-label">
        {/* <input
          className={`form-control shadow-none ${meta.touched && meta.error && "is-invalid"}`}
          {...field}
          type="number"
          {...props}
          placeholder=" "
          name={field.name}
          id={field.name}
          maxlength="10"
        /> */}
        <input
          className={`form-control shadow-none ${meta.touched && meta.error && "is-invalid"}`}
          {...field}
          type="number"
          {...props}
          placeholder=" "
          name={field.name}
          id={field.name}
          maxlength="10"
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
        />
        <label htmlFor={field.name}>{label}</label>
      </div>
      <ErrorMessage component="span" name={field.name} className="error" />
    </div>
  );
}

export default NumField;
